<template>
  <div class="loading-spinner">
    <a-icon
      icon="fa:solid:spinner"
      size="4x"
      spin />
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
};
</script>

<style lang="scss" scoped>
.loading-spinner {
  text-align: center;
  color: var( --color-primary-500 );
}
</style>
