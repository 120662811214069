<template>
  <section class="assessments-section">
    <div class="container">
      <h2 class="assessments-section__heading">
        Assessments
      </h2>

      <div v-if="loading">
        <loading-spinner />
      </div>

      <div v-else-if="error || !groups">
        <p class="assessments-section__error">
          Error loading assessments data
        </p>
      </div>

      <state-manager
        v-else
        v-slot="{ setState, state }"
        :value="stateKey">
        <ul class="assessments-nav">
          <li
            v-for="(_, key) in groups"
            :key="key"
            class="assessments-nav__item">
            <button
              class="assessments-nav__button"
              :class="{ 'assessments-nav__button--active': key === state }"
              @click="setState( key )">
              {{ key }}
            </button>
          </li>
        </ul>

        <state-wrapper
          v-for="(group, key) in groups"
          :key="key"
          :name="key">
          <ul class="assessments-list">
            <li
              v-for="(assessment, index) in group"
              :key="index"
              class="assessments-list__item">
              <nuxt-link
                :to="assessment.routePath"
                class="assessments-list__link">
                <img
                  v-if="assessment.productIconUrl"
                  :src="`${assessment.productIconUrl}?width=80`"
                  :alt="assessment.productIconAltText"
                  role="presentation"
                  class="assessments-list__image">
                <div
                  v-else
                  role="presentation"
                  class="assessments-list__placeholder" />

                <h3 class="assessments-list__name">
                  {{ assessment.productName }}
                </h3>
              </nuxt-link>
            </li>
          </ul>
        </state-wrapper>
      </state-manager>
    </div>
  </section>
</template>

<script lang="ts" setup>

type AssessmentGroup = {
  routePath : string,
  productIconUrl? : string,
  productIconAltText? : string,
  productName? : string
};

const loading = ref( false );

loading.value = true;
const {
  error,
  data: groups,
} = await useApiFetch<Record<string, AssessmentGroup[]>>( "/api/assessments/groups" );
loading.value = false;

const stateKey = computed( () => {
  if ( groups.value ) {
    const keys = Object.keys( groups.value );
    return keys.length ? keys[0] : "";
  }

  return "";
} );

</script>

<style lang="scss" scoped>
.assessments-section {
  background: #ebebeb;
  padding: 40px 0 50px;

  &__heading {
    font-size: 2.5rem;
  }
}

.assessments-nav {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: 0 0 40px;
  flex-wrap: wrap;
  gap: 15px 0;

  &__item {
    flex: 0 0 calc( 12.5% - 1px );
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    @media (width <= 991px) {
      flex-grow: 1;
    }
  }

  &__button {
    width: 100%;
    display: flex;
    align-items: flex-start;
    border: 0;
    background: none;
    padding: 10px 0;
    font-size: 1.25rem;
    font-weight: 500;
    border-bottom: 5px solid #d7d7d7;
    color: #7f7f7f;
    margin-right: 20px;
    text-align: left;

    &:hover {
      color: #666;
      border-bottom-color: #bbb;
      cursor: pointer;
    }

    &--active {
      color: var( --color-primary-500 ) !important;
      border-bottom-color: var( --color-primary-500 ) !important;
    }

    &:focus {
      border-bottom-color: var( --color-primary-500 );
      outline: none;
    }
  }
}

.assessments-list {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  flex-wrap: wrap;
  margin-bottom: -20px;

  &__link {
    display: block;
    text-decoration: none !important;
  }

  &__image {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 15px;
  }

  &__placeholder {
    width: 80px;
    height: 80px;
    background: var( --color-gray-300 );
    margin-bottom: 15px;
    border-radius: 10px;
  }

  &__item {
    margin-right: 20px;
    flex: 0 0 16.66%;
    margin-bottom: 20px;
  }
}
</style>
